import { Box, Flex, Text, Icon } from "@chakra-ui/react";
import { FC, useMemo, useState } from "react";
import { HiChevronDoubleLeft } from "react-icons/hi";
import { ScrollBar } from "src/components/ScrollBar";
import { CHAT_AREA } from "src/const";
import { ChatGroupType, ChatType } from "src/interface/Chat";
import { ChatIconButton } from "src/pages/Main/components/ChatIconButton";
import { ChatSettingModal } from "src/pages/Main/components/ChatSettingModal";
import { isDevEnv } from "src/util/env";
import { SidebarItem } from "./SidebarItem";
import { MdInfoOutline } from "react-icons/md";

export const SideBar: FC<{
  intercomGroupList: ChatGroupType[];
  shopId: string | null;
  setIsSideBar: (isSideBar: boolean) => void;
  checkChatRoom: (chatId?: string) => boolean;
  changeSideBar: (isSideBar: boolean) => void;
}> = ({
  intercomGroupList,
  setIsSideBar,
  checkChatRoom,
  shopId,
  changeSideBar,
}) => {
  const roomintercomGroupList = useMemo(() => {
    if (shopId) return [];
    return intercomGroupList.filter(
      (chatGroup) => chatGroup.chatType === ChatType.Room
    );
  }, [intercomGroupList, shopId]);
  return (
    <Box
      w="208px"
      borderRight="1px solid rgba(0,0,0,0.1)"
      position="relative"
      minWidth="200px"
      h="100%"
    >
      <Flex
        h={`${CHAT_AREA.HEADER_HEIGHT}px`}
        justifyContent="flex-end"
        p="8px"
      >
        {isDevEnv() && (
          <Box mr="10px">
            <ChatSettingModal />
          </Box>
        )}
        <ChatIconButton
          ariaLabel={"sidebar-close"}
          icon={<HiChevronDoubleLeft />}
          onClick={() => {
            setIsSideBar(false);
            changeSideBar(false);
          }}
        />
      </Flex>
      <ScrollBar height={`calc(100% - ${CHAT_AREA.HEADER_HEIGHT}px)`}>
        <Box w="100%" h="87px">
          <Flex
            w="100%"
            h="28px"
            p="4px"
            background="rgba(81, 104, 127, 1)"
            justifyContent="center"
            alignItems="center"
          >
            <Icon
              fontSize="15px"
              as={MdInfoOutline}
              aria-label={"confirm"}
              color="rgba(255, 255, 255, 1)"
            />
            <Box ml="2px" flexDirection="column">
              <Text
                fontSize="12px"
                fontWeight={600}
                // whiteSpace="nowrap"
                overflow="hidden"
                color="rgba(255, 255, 255, 1)"
              >
                メッセージ取得間隔
              </Text>
            </Box>
          </Flex>
          <Box
            w="100%"
            h="67px"
            p="8px 16px"
            background="rgba(109, 135, 162, 1)"
            borderBottom="1px solid rgba(226, 231, 236, 1)"
            flexDirection="column"
          >
            <Text
              fontSize="10px"
              fontWeight={600}
              overflow="hidden"
              color="rgba(255, 255, 255, 1)"
            >
              インカムメッセージの取得間隔は、通常30秒です。表示中のグループは5秒間隔で取得します。
            </Text>
          </Box>
        </Box>
        <Box p="24px 8px">
          <Box>
            {roomintercomGroupList.map((chatGroup, index) => (
              <SidebarItem
                key={index}
                chatGroup={chatGroup}
                checkChatRoom={checkChatRoom}
              />
            ))}
          </Box>
        </Box>
      </ScrollBar>
    </Box>
  );
};
