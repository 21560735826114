/**realtimedbのどこに書き込むかを返却する */
export const getAccessDocComapnayAll = (companyId: string): string => {
  return `chatRoom/company/${companyId}`;
};

export const getAccessDocComapnay = (companyId: string): string => {
  const doc = getAccessDocComapnayAll(companyId);
  return `${doc}/companyChat/`;
};

export const getAccessDocComapnayLog = (companyId: string): string => {
  return `chatLog/company/${companyId}/companyChat/`;
};

export const getAccessDocComapnayLastChatTime = (companyId: string): string => {
  return `lastChatTime/company/${companyId}/timestamp`;
};

export const getAccessDocRoom = (companyId: string, roomId: string): string => {
  const doc = getAccessDocComapnayAll(companyId);
  return `${doc}/room/${roomId}/roomChat/`;
};

export const getAccessDocUserData = (companyId: string): string => {
  return `userData/company/${companyId}/user/`;
};

export const getAccessDocUserInfo = (
  companyId: string,
  userId: string
): string => {
  const doc = getAccessDocUserData(companyId);
  return `${doc}${userId}/userInfo`;
};

export const getAccessDocUserChatRoomCheckTime = (
  companyId: string,
  userId: string
): string => {
  const doc = getAccessDocUserData(companyId);
  return `${doc}${userId}/chatRoomCheckTime`;
};
