import { Box, Flex, Text, Spacer } from "@chakra-ui/react";
import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { IoArrowDown } from "react-icons/io5";
import { CHAT_AREA, TEXT } from "src/const";
import { useQueryString } from "src/hooks/useQueryString";
import { useMain } from "src/pages/Main/hooks/useMain";
import { useMessageEvent } from "src/pages/Main/hooks/useMessageEvent";
import { ChatHeader } from "./components/ChatHeader";
import { ChatMessageArea } from "./components/ChatMessageArea";
import { ChatTextArea } from "./components/ChatTextArea";
import { MinimumChatIcon } from "./components/MinimumChatIcon";
import { SideBar } from "./components/SideBar";
import { useUsers } from "./hooks/useUsers";
import { selectedChatAtom } from "src/pages/Main/atoms/selectedChat";
import { useAtomValue } from "jotai";
import { useToken } from "src/pages/Main/hooks/useToken";
import { useAuth } from "src/hooks/useAuth";
import { useFireBase } from "src/hooks/useFirebase";
import { SendUserRoleType } from "src/interface/Chat";
import { errorTypeAtom } from "src/pages/Main/atoms/errorType";
import { ErrorArea } from "./components/ErrorArea";

const ChatPage: FC = () => {
  const callingAudioRef = useRef<HTMLAudioElement>(null);
  useAuth();
  useFireBase();
  const { getIconImage } = useUsers();
  // TODO: 権限も同時に確認する(スタッフ用アカウントの場合は固定される)
  const shopId = useQueryString("shop_id");
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const selectedChat = useAtomValue(selectedChatAtom);
  const [viewDownScrollButton, setViewDownScrollButton] =
    useState<boolean>(true);
  const isViewOnFrame = window !== window.parent;
  const errorType = useAtomValue(errorTypeAtom);
  useToken();
  const [isChatOpen, setIsChatOpen] = useState(isViewOnFrame ? false : true);
  const [frameHeight, setFrameHeight] = useState<number>(CHAT_AREA.ALL_HEIGHT);
  const downScroll = () => {
    if (scrollContainerRef && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  };
  const {
    chatList,
    intercomGroupList,
    requestChat,
    callChatTextRef,
    unreadAllChatMessages,
    isView,
    setIsView,
    checkChatRoom,
    chatRoomCheckTime,
    unreadMessagesOnRoom,
    lastGetChatTime,
  } = useMain(isViewOnFrame, downScroll, shopId);

  const { closeChat, changeSideBar, sendMessageLog } = useMessageEvent({
    unreadAllChatMessages,
    unreadMessagesOnRoom,
    setIsChatOpen,
    setFrameHeight,
  });

  const [isSideBar, setIsSideBar] = useState(false);

  useEffect(() => {
    if (!isViewOnFrame) {
      // 別タブで開いている場合はサイドバーを初期から開く
      setIsSideBar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [isViewOnFrame]);

  const bodyRef = useRef<HTMLDivElement>(null);
  const messageAreaRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLDivElement>(null);

  const subscribe = useCallback((onStoreChange: () => void) => {
    const observer = new ResizeObserver((entries) => {
      entries.forEach(() => {
        onStoreChange();
      });
    });
    if (textAreaRef.current) {
      observer.observe(textAreaRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  const textAreaHeight = React.useSyncExternalStore(subscribe, () => {
    return textAreaRef.current?.offsetHeight;
  });

  const useWindowSize = (): number[] => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      const updateSize = (): void => {
        setSize([window.innerWidth, window.innerHeight]);
      };

      window.addEventListener("resize", updateSize);
      updateSize();

      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };

  const [width, height] = useWindowSize();

  useEffect(() => {
    if (bodyRef.current) {
      const bodyHeight = bodyRef.current?.clientHeight;
      if (bodyHeight && textAreaHeight && messageAreaRef.current) {
        const messageAreaHeight =
          bodyHeight - (textAreaHeight + CHAT_AREA.HEADER_HEIGHT * 2);
        messageAreaRef.current.style.height = `${String(messageAreaHeight)}px`;
      } else {
        return;
      }
    }
  }, [textAreaHeight, width, height, frameHeight]);

  const requestIntercom = useCallback(async () => {
    requestChat();
    if (!selectedChat) return;
    if (intercomGroupList.length === 0) return;
    const intercomGroup = intercomGroupList.find(
      (intercomGroup) => intercomGroup.id === selectedChat
    );
    if (!intercomGroup) return;
    sendMessageLog(
      intercomGroup.id,
      intercomGroup.name,
      SendUserRoleType.RemoteUser
    );
  }, [selectedChat, intercomGroupList]);

  const viewNewScrollMessage =
    intercomGroupList.find((chatGroup) => chatGroup.id === selectedChat)
      ?.unreadMessgesNum !== 0;

  return isView ? (
    <Box
      w="100%"
      h={isViewOnFrame ? frameHeight : "100dvh"}
      overflow="hidden"
      position="relative"
      ref={bodyRef}
      backgroundColor="rgba(244, 246, 248, 1)"
    >
      {/** streamのaudioを生成する場所*/}
      <audio
        ref={callingAudioRef}
        src={`${process.env.PUBLIC_URL}/sound/calling.mp3`}
      />
      <Flex h="100%">
        {isSideBar && !errorType && (
          <SideBar
            intercomGroupList={intercomGroupList}
            shopId={shopId}
            setIsSideBar={setIsSideBar}
            checkChatRoom={checkChatRoom}
            changeSideBar={changeSideBar}
          />
        )}
        <Box w="100%" backgroundColor="#FFFFFF">
          {!errorType && viewDownScrollButton && (
            <Flex
              position="absolute"
              right="16px"
              bottom="104px"
              w={viewNewScrollMessage ? "265px" : "32px"}
              h="32px"
              zIndex={2}
              cursor="pointer"
              onClick={downScroll}
            >
              {viewNewScrollMessage && (
                <Box
                  w="217px"
                  display="flex"
                  p="5px 8px"
                  justifyContent="space-between"
                  alignItems="center"
                  background="#FFF"
                  borderRadius="16px"
                  border="2px solid rgba(34, 197, 94, 1)"
                  boxShadow="0px 1px 8px 0px rgba(0, 0, 0, 0.16)"
                >
                  <Text
                    fontSize="14px"
                    color="rgba(34, 197, 94, 1)"
                    fontWeight={600}
                  >
                    {TEXT.MESSAGE.NEW_MESSAGE_TEXT}
                  </Text>
                </Box>
              )}
              <Spacer />
              <Box
                backgroundColor="rgba(188, 186, 180, 0.7)"
                borderRadius="4px"
                w="32px"
                h="32px"
                p="4px"
              >
                <IoArrowDown fontSize="24px" color="#FFF" />
              </Box>
            </Flex>
          )}
          <ChatHeader
            chatName={
              intercomGroupList.find(
                (chatGroup) => chatGroup.id === selectedChat
              )?.name
            }
            closeChat={closeChat}
            setIsSideBar={setIsSideBar}
            changeSideBar={changeSideBar}
            isSideBar={isSideBar}
            getIconImage={getIconImage}
            shopId={shopId}
            lastGetChatTime={lastGetChatTime}
            isError={errorType ? true : false}
          />
          {errorType ? (
            <Box>
              <ErrorArea />
            </Box>
          ) : (
            <>
              <Box ref={messageAreaRef}>
                <ChatMessageArea
                  scrollContainerRef={scrollContainerRef}
                  chatList={chatList}
                  isChatOpen={isChatOpen}
                  setViewDownScrollButton={setViewDownScrollButton}
                  checkChatRoom={checkChatRoom}
                  downScroll={downScroll}
                  getIconImage={getIconImage}
                  frameHeight={frameHeight}
                  chatRoomCheckTime={chatRoomCheckTime}
                />
              </Box>
              <Box
                ref={textAreaRef}
                position="absolute"
                bottom="0"
                left={isSideBar ? "200px" : "0px"}
                width={`calc(100% - ${isSideBar ? "200px" : "0px"})`}
                zIndex="3"
              >
                <ChatTextArea
                  callChatTextRef={callChatTextRef}
                  requestChat={requestIntercom}
                />
              </Box>
            </>
          )}
        </Box>
      </Flex>
    </Box>
  ) : (
    <MinimumChatIcon
      unreadAllChatMessages={unreadAllChatMessages}
      setIsView={setIsView}
    />
  );
};
export default ChatPage;
