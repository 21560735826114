import { useEffect, useState } from "react";
import { useEvent } from "react-use";
import {
  ApproveFrameReceiveAction,
  FRAME_RECEIVE_ACTION,
  FRAME_SEND_ACTION,
} from "src/const";
import { actionMessageType } from "src/interface/Messages";
import DateTime from "src/util/dateTime";
import { isNoticeMuteAtom } from "src/pages/Main/atoms/isNoticeMute";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { updateUser } from "src/util/realtimedb/updateUserInfo";
import { meAtom, meInfoAtom } from "src/pages/Main/atoms/me";
import { databaseAtom } from "src/pages/Main/atoms/firebase";
import { selectedChatAtom } from "src/pages/Main/atoms/selectedChat";
import { SendUserRoleType } from "src/interface/Chat";
import { isToastOnAtom } from "../atoms/isToastOn";
export const useMessageEvent = ({
  unreadAllChatMessages,
  setIsChatOpen,
  setFrameHeight,
  unreadMessagesOnRoom,
}: {
  unreadAllChatMessages: number;
  setIsChatOpen: (isChatOpen: boolean) => void;
  setFrameHeight: (frameHeight: number) => void;
  unreadMessagesOnRoom: {
    roomId: string;
    roomName: string;
    unreadMessgesNum: number;
  }[];
}) => {
  const [sendMessageTime, setSendMessageTime] = useState<{
    time: Date;
    groupId: string;
  }>();
  const setSelectedChat = useSetAtom(selectedChatAtom);
  const isNoticeMute = useAtomValue(isNoticeMuteAtom);
  const isToastOn = useAtomValue(isToastOnAtom);
  // NOTE: 一先ず、音声通知はOFFにするが仕様変更の可能性あり
  const isViewOnFrame = window !== window.parent;
  const nowTime = () => {
    const dateTime = new DateTime();
    return dateTime.toString(new Date());
  };
  const database = useAtomValue(databaseAtom);
  const me = useAtomValue(meAtom);
  const meInfo = useAtomValue(meInfoAtom);

  useEffect(() => {
    if (!isViewOnFrame) return;
    // postmessageを送信する
    console.log({
      action: FRAME_SEND_ACTION.INTERCOM_UNREAD_MESSAGES_NUM,
      from: "rura_chat",
      time: nowTime(),
      data: {
        num: unreadAllChatMessages,
        isNoticeMute: true,
        isToastOn,
        unreadMessagesOnRoom,
      },
    });
    sendMessage({
      action: FRAME_SEND_ACTION.INTERCOM_UNREAD_MESSAGES_NUM,
      from: "rura_chat",
      time: nowTime(),
      data: {
        num: unreadAllChatMessages,
        unreadMessagesOnRoom,
        isNoticeMute: true,
        // isNoticeMute,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [unreadAllChatMessages, unreadMessagesOnRoom, isNoticeMute]);

  useEffect(() => {
    if (!isViewOnFrame) return;
    if (!sendMessageTime) return;
    if (unreadMessagesOnRoom.length === 0) return;
    const sentMessageRoom = unreadMessagesOnRoom.find(
      (room) => room.roomId === sendMessageTime.groupId
    );
    if (!sentMessageRoom) return;
    const sendMessageDurationSecond =
      (new Date().getTime() - sendMessageTime.time.getTime()) / 1000;
    setSendMessageTime(undefined);
    // sendMessageDurationSecondが1分以内かどうか
    if (sendMessageDurationSecond >= 60) return;
    // postmessageを送信する
    console.log({
      action: FRAME_SEND_ACTION.INTERCOM_QUICK_REPLY_RECEIVED,
      from: "rura_chat",
      time: nowTime(),
      data: {
        intercomGroupId: sentMessageRoom.roomId,
        intercomGroupName: sentMessageRoom.roomName,
        durationSecond: sendMessageDurationSecond,
      },
    });
    sendMessage({
      action: FRAME_SEND_ACTION.INTERCOM_QUICK_REPLY_RECEIVED,
      from: "rura_chat",
      time: nowTime(),
      data: {
        intercomGroupId: sentMessageRoom.roomId,
        intercomGroupName: sentMessageRoom.roomName,
        durationSecond: sendMessageDurationSecond,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [unreadMessagesOnRoom, sendMessageTime]);

  const sendMessage = (message: actionMessageType) => {
    if (!isViewOnFrame) return;
    window.parent.postMessage(JSON.stringify(message), "*");
  };

  const closeChat = () => {
    if (!isViewOnFrame) return;
    sendMessage({
      action: FRAME_SEND_ACTION.INTERCOM_CLOSE,
      from: "rura_chat",
      time: nowTime(),
      data: {
        num: unreadAllChatMessages,
      },
    });
  };

  const changeSideBar = (isSideBarOpen: boolean) => {
    if (!isViewOnFrame) return;
    sendMessage({
      action: isSideBarOpen
        ? FRAME_SEND_ACTION.INTERCOM_SIDEBAR_OPEN
        : FRAME_SEND_ACTION.INTERCOM_SIDEBAR_CLOSE,
      from: "rura_chat",
      time: nowTime(),
      data: {},
    });
  };

  useEvent(
    "message",
    (event: any) => {
      if (!database || !me || !meInfo) return;
      if (event.data) {
        try {
          const message = JSON.parse(event.data);
          if (
            message.action &&
            ApproveFrameReceiveAction.includes(message.action)
          ) {
            console.warn("rura-chatでメッセージを受信しました", message);
            if (message.action === FRAME_RECEIVE_ACTION.CHANGE_STATUS) {
              console.warn(message.data);
              updateUser(
                database,
                me,
                message.data.status,
                meInfo.noticeMute ?? true,
                meInfo.isToastOn ?? true
              );
            }
            if (message.action === FRAME_RECEIVE_ACTION.INTERCOM_CLOSE) {
              setIsChatOpen(false);
              setSelectedChat(undefined);
            }
            if (message.action === FRAME_RECEIVE_ACTION.INTERCOM_OPEN) {
              setIsChatOpen(true);
              setFrameHeight(message.data.height);
              setSelectedChat(message.data.roomId);
            }
          }
        } catch (e) {
          // nothing to do
        }
      }
    },
    window
  );

  const sendMessageLog = (
    groupId: string,
    groupName: string,
    userRole: SendUserRoleType
  ) => {
    if (!isViewOnFrame) return;
    sendMessage({
      action: FRAME_SEND_ACTION.INTERCOM_SEND_MESSAGE,
      from: "rura_chat",
      time: nowTime(),
      data: {
        intercomGroupId: groupId,
        intercomGroupName: groupName,
        intercomUserRole: userRole,
      },
    });
    setSendMessageTime({ time: new Date(), groupId });
  };

  return { closeChat, changeSideBar, sendMessageLog };
};
