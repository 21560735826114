import { Box, Spinner, Text, Flex } from "@chakra-ui/react";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { CHAT_AREA, TEXT } from "src/const";
import { Chat, ChatRoomCheckTime } from "src/interface/Chat";
import { MyChatMessage } from "./ChatMessage/MyChatMessage";
import { OtherChatMessage } from "./ChatMessage/OtherChatMessage";
import { useAtomValue } from "jotai";
import { selectedChatAtom } from "src/pages/Main/atoms/selectedChat";
import { meAtom } from "src/pages/Main/atoms/me";
import { replaceUserId } from "src/util/getString";
import { usersAtom } from "src/pages/Main/atoms/users";
import { isSendMessageLoadingAtom } from "src/pages/Main/atoms/isSendMessageLoading";
import { errorTypeAtom } from "src/pages/Main/atoms/errorType";

export const ChatMessageArea: FC<{
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  chatList: Chat[];
  isChatOpen: boolean;
  setViewDownScrollButton: React.Dispatch<React.SetStateAction<boolean>>;
  checkChatRoom: (chatId?: string) => boolean;
  downScroll: () => void;
  getIconImage: (userId: string | undefined) => string | null | undefined;
  frameHeight: number;
  chatRoomCheckTime: ChatRoomCheckTime | null;
}> = ({
  scrollContainerRef,
  chatList,
  isChatOpen,
  setViewDownScrollButton,
  checkChatRoom,
  downScroll,
  getIconImage,
  frameHeight,
  chatRoomCheckTime,
}) => {
  const me = useAtomValue(meAtom);
  const userId: string | undefined = useMemo(() => {
    return replaceUserId(me?.datastoreId ?? "");
  }, [me]);
  const [isInit, setIsInit] = useState<boolean>(false);
  const selectedChat = useAtomValue(selectedChatAtom);
  const sortedByTimestampChatList = chatList.sort((a, b) => {
    const dateA: any = new Date(a.timestamp);
    const dateB: any = new Date(b.timestamp);
    return dateA - dateB;
  });

  const checkedTime = useMemo(() => {
    if (!chatRoomCheckTime || !selectedChat) return undefined;
    return chatRoomCheckTime[selectedChat]?.checkTime;
  }, [chatRoomCheckTime, selectedChat]);

  const unreadMessages = useMemo(() => {
    if (!checkedTime) return sortedByTimestampChatList.length;
    const unreadMessages = sortedByTimestampChatList.filter((chat) => {
      return new Date(chat.timestamp) >= new Date(checkedTime);
    });
    return unreadMessages.length;
  }, [checkedTime, sortedByTimestampChatList]);

  // メッセージ全体の高さ取得用
  const scrollContentRef = useRef<HTMLDivElement>(null);
  const isViewOnFrame = window !== window.parent;

  useEffect(() => {
    // コンポーネントがマウントされた後にスクロール操作を実行する
    if (sortedByTimestampChatList.length > 0) {
      if ((isViewOnFrame && isChatOpen) || !isViewOnFrame) {
        if (scrollContainerRef && scrollContainerRef.current) {
          if (
            scrollContentRef &&
            scrollContentRef.current &&
            scrollContentRef.current.clientHeight <
              frameHeight -
                (CHAT_AREA.TEXT_AREA_HEIGHT + CHAT_AREA.HEADER_HEIGHT * 2)
          ) {
            // scrollContentRefの高さがchatサイズよりも小さければ既読にする
            // checkChatRoom();
            setViewDownScrollButton(false);
          }
          let isScrollCheckTime = false;
          scrollContainerRef.current.addEventListener("scroll", function () {
            // ページの一番下にスクロールしたかどうかをチェック
            if (
              scrollContainerRef &&
              scrollContainerRef.current &&
              scrollContentRef &&
              scrollContentRef.current
            ) {
              // 別ウインドウ対策でスクロール量をパーセント化
              // スクロール量100%以下の場合にスクロールボタン表示
              if (
                (scrollContainerRef.current.scrollTop /
                  (scrollContentRef.current.clientHeight -
                    scrollContainerRef.current.clientHeight)) *
                  100 >=
                100
              ) {
                if (!isScrollCheckTime && unreadMessages > 0) {
                  checkChatRoom();
                  isScrollCheckTime = true;
                }
                setViewDownScrollButton(false);
              } else {
                setViewDownScrollButton(true);
              }
            }
          });
        }
      }
    }
  }, [
    scrollContainerRef,
    sortedByTimestampChatList,
    unreadMessages,
    isInit,
    downScroll,
    checkChatRoom,
    setViewDownScrollButton,
    isChatOpen,
    isViewOnFrame,
    frameHeight,
  ]);

  useEffect(() => {
    if (isInit) return;
    let timeout: NodeJS.Timeout | undefined = undefined;
    if (
      scrollContainerRef &&
      scrollContainerRef.current &&
      sortedByTimestampChatList.length > 0
    ) {
      timeout = setTimeout(() => {
        downScroll();
        setIsInit(true);
      }, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- not necessary
  }, [isInit, scrollContainerRef, sortedByTimestampChatList]);

  useEffect(() => {
    setIsInit(false);
  }, [selectedChat]);

  useEffect(() => {
    if (!userId) return;
    if (isInit) return;
    const timeout = setTimeout(() => {
      setIsInit(true);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [userId, isInit, setIsInit, selectedChat]);

  const isBetweenDays = (beforeChat: Chat | undefined, chat: Chat) => {
    // 日付の切り替わりを取得する
    if (!beforeChat) return false;
    if (
      new Date(chat.timestamp).getDate() !==
      new Date(beforeChat.timestamp).getDate()
    )
      return true;
    return false;
  };
  const users = useAtomValue(usersAtom);

  const timestampToJpString = (timestamp: string) => {
    // タイムスタンプを日本語の文字列に変換する 2024年 9月17日(火)
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dayOfWeek = date.getDay();
    const WEEK_DAYS = ["日", "月", "火", "水", "木", "金", "土"];

    return `${year}年 ${month}月${day}日(${WEEK_DAYS[dayOfWeek]})`;
  };

  const isSendMessageLoading = useAtomValue(isSendMessageLoadingAtom);

  return (
    <Box
      overflowY="scroll"
      className="hide-scrollbar"
      height="100%"
      position="relative"
      padding="0 0 32px 0"
      ref={scrollContainerRef}
    >
      {isInit && !userId && (
        <Box w="100%">
          <Box m="auto" p="8px">
            <Text fontSize="16px" fontWeight={600} color="red">
              {TEXT.MESSAGE.ERROR_MESSAGE_AREA}
            </Text>
          </Box>
        </Box>
      )}
      {!selectedChat ||
        (!isInit && (
          <Box w="100%" mt="300px">
            <Box w="50px" m="auto">
              <Spinner speed="1.0s" size="xl" />
            </Box>
          </Box>
        ))}

      <Box ref={scrollContentRef}>
        {sortedByTimestampChatList.map((chat: Chat, index: number) => (
          <Box
            w="calc(100% - 32px)"
            m={`0 auto`}
            display="flow-root"
            boxSizing="border-box"
            pb="8px"
            key={index}
          >
            {isBetweenDays(
              index > 0 ? sortedByTimestampChatList[index - 1] : undefined,
              chat
            ) && (
              <Flex w="100%" alignItems="center" h="36px" p="8px 0px">
                <Box
                  flex="1"
                  borderBottom="1px solid rgba(135, 134, 130, 1)"
                  ml="8px"
                />
                <Box textAlign="center" h="20px" p="0px 4px">
                  <Text
                    fontSize="14px"
                    fontWeight={300}
                    color="rgba(135, 134, 130, 1)"
                  >
                    {timestampToJpString(chat.timestamp)}
                  </Text>
                </Box>
                <Box
                  flex="1"
                  borderBottom="1px solid rgba(135, 134, 130, 1)"
                  mr="8px"
                />
              </Flex>
            )}
            {chat.chatUserId === userId ? (
              <MyChatMessage
                chat={chat}
                beforeChat={
                  index > 0 ? sortedByTimestampChatList[index - 1] : undefined
                }
                icon={getIconImage(chat.chatUserId)}
              />
            ) : (
              <OtherChatMessage
                chat={chat}
                beforeChat={
                  index > 0 ? sortedByTimestampChatList[index - 1] : undefined
                }
                icon={getIconImage(chat.chatUserId)}
              />
            )}
          </Box>
        ))}
        {isSendMessageLoading && (
          <Box w="212px" h="36px" p="8px 0px" alignItems="center" m="auto">
            <Flex w="212px" h="20px" alignItems="center">
              <Spinner
                speed="1.6s"
                size="sm"
                color="rgba(78, 209, 126, 1)"
                pt="4px"
              />
              <Text fontWeight={300} fontSize="14px" textAlign="center">
                メッセージを送信しています...
              </Text>
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  );
};
