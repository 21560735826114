import { useState, useEffect } from "react";
import { Cookies } from "react-cookie";
import { TEXT } from "src/const";
import {
  useSigninMutation,
  useGetCustomVariablesForVoytQuery,
  GetCustomVariablesForVoytQuery as GetCustomVariablesForVoytQueryType,
  useMeQuery,
} from "src/gql/generated/graphql";
import { isDevEnv } from "src/util/env";
import { cookieKeyToken } from "src/util/token";
import { gql } from "urql";
import { VARIABLE } from "src/const";
import { useNavigate } from "react-router-dom";
import { isBlank } from "src/util/textUtil";

export const SigninQuery = gql`
  mutation Signin($userId: String!, $password: String!) {
    signin(input: { userId: $userId, password: $password }) {
      __typename
      ... on SigninSuccess {
        token
      }
      ... on SigninFailed {
        message
      }
      ... on SigninFrozen {
        message
      }
      ... on SigninRejection {
        message
      }
    }
  }
`;

export const GetCustomVariablesForVoytQuery = gql`
  query GetCustomVariablesForVoyt($variables: [String!], $owner: ID!) {
    customVariables(variables: $variables, owner: $owner) {
      nodes {
        variable
        value
      }
    }
  }
`;

export const useLogin = () => {
  const [, signin] = useSigninMutation();
  const [userId, setUserId] = useState<string>("");
  const [mainUrl, setMainUrl] = useState<string>();
  const [password, setPassword] = useState<string>("");
  const [shopId, setShopId] = useState<string>("");
  const [alertText, setAlertText] = useState<string>("");
  const [{ data: myData }, getMe] = useMeQuery({
    pause: true,
  });
  const [{ data: customVariableData }, getCustomVariable] =
    useGetCustomVariablesForVoytQuery({
      variables: {
        variables: [VARIABLE.VOYT_USER_ID, VARIABLE.VOYT_PASSWORD],
        owner: myData?.me.id ?? "",
      },
      pause: isBlank(myData?.me.id) && mainUrl ? true : false,
    });
  const navigate = useNavigate();

  useEffect(() => {
    if (!mainUrl) return;
    if (!myData || !myData?.me.id) return;
    getCustomVariable({
      variables: {
        variables: [VARIABLE.VOYT_USER_ID, VARIABLE.VOYT_PASSWORD],
        owner: myData?.me.id ?? "",
      },
    });
  }, [myData, mainUrl]);

  const getVariableValue = (
    variable: GetCustomVariablesForVoytQueryType | undefined
  ) => {
    const result = new Map<string, string>();
    variable?.customVariables.nodes.reduce((acc, node) => {
      return acc.set(node.variable, node.value);
    }, result);
    return result;
  };

  useEffect(() => {
    if (!customVariableData) return;
    if (!mainUrl) return;
    const voytSignInInfoByVariable = getVariableValue(customVariableData);
    if (voytSignInInfoByVariable.size === 0) return;
    if (
      voytSignInInfoByVariable.has(VARIABLE.VOYT_USER_ID) &&
      voytSignInInfoByVariable.has(VARIABLE.VOYT_PASSWORD)
    ) {
      // NOTE: atomへのセットはuseAuth.tsで行っているので、ここでは不要
      navigate(mainUrl);
    } else {
      setAlertText(TEXT.LOGIN.ERROR_COLLABO_CUSTOM_VARIABLE);
      return;
    }
  }, [customVariableData, mainUrl]);

  const login = async (path: string) => {
    setMainUrl(undefined);
    // user_id, password, 店舗IDでのログイン
    await signin({ userId, password })
      .then(async (result) => {
        const { data } = result;
        switch (data?.signin.__typename) {
          case "SigninSuccess": {
            const cookie = new Cookies();
            cookie.set(cookieKeyToken, data.signin.token, {
              maxAge: 10 * 24 * 60 * 60,
              path: "/",
              domain: isDevEnv() ? "localhost" : ".rura.tokyo",
            });
            let url = `${path}?user_id=${userId}`;
            if (shopId) {
              url += `&shop_id=${shopId}`;
            }
            setAlertText("");
            return { success: true, mainUrl: url } as const;
          }
          case "SigninFailed":
            setAlertText(TEXT.LOGIN.ERROR_SIGNIN_FAILED);
            return { success: false } as const;
          case "SigninFrozen":
            setAlertText(TEXT.LOGIN.ERROR_SIGNIN_FROZEN);
            return { success: false } as const;
          case "SigninRejection":
            setAlertText(TEXT.LOGIN.ERROR_SIGNIN_REJECTION);
            return { success: false } as const;
          default:
            return { success: false } as const;
        }
      })
      .then((result) => {
        if (result.success) {
          getMe({ requestPolicy: "network-only" });
          setMainUrl(result.mainUrl);
        }
      });
  };

  return {
    userId,
    setUserId,
    password,
    setPassword,
    shopId,
    setShopId,
    alertText,
    login,
  };
};
