export interface ChatUser {
  userId: string;
  profileImg?: string;
  name: string;
  status: UserStatusType;
  timestamp: string;
  noticeMute?: boolean;
  isToastOn?: boolean;
}

export enum UserStatusType {
  Rest = "rest",
  Wait = "wait",
  Serving = "serving",
  OffWork = "off_work",
}

export enum messageType {
  Text = "text",
  Stamp = "stamp",
}

export interface ChatReaction {
  userId: string;
  emoji: string;
}
export interface Chat {
  id: string;
  text: string;
  userType: UserType;
  chatUserId: string | undefined;
  intercomAccount?: {
    id: string;
    account: string;
    name: string;
  };
  timestamp: string;
  messageType: messageType;
  userRole?: SendUserRoleType;
}

export interface ALLChat {
  [roomId: string]: { [id: string]: Chat };
}

export interface ChatRoom {
  id: string;
  name: string;
  tooltipText?: string;
  chatType: ChatType;
  chatUsers: string[];
  isSelected: boolean;
  roomId?: string;
}

/** インカム送信ユーザー種別 */
export enum SendUserRoleType {
  /** 遠隔スタッフ */
  RemoteUser = "remote_user",
  /** 現地スタッフ */
  SupportUser = "support_user",
  /** インカムアプリ */
  IntercomApp = "intercom_app",
}

export interface ChatGroup {
  id: string;
  name: string;
  chatType: ChatType;
  chatUsers: string[];
  isSelected: boolean;
}

export interface ChatRoomCheckTime {
  [id: string]: {
    id: string;
    chatType: ChatType;
    checkTime: string;
  };
}

export type ChatGroupType = ChatRoom & { unreadMessgesNum: number };

/** ユーザー種別 */
export enum UserType {
  /** システム */
  System = "system",
  /** ユーザー */
  User = "user",
}

export enum ChatType {
  Company = "company",
  Room = "room",
  Pc = "pc",
  System = "system",
  Users = "users",
  FeedBack = "feedback",
  FeedBackResponse = "feedback-response",
}

export enum ChatMessageType {
  Text = "text",
  Reaction = "reaction",
}
