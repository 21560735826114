import { Box, Flex, HStack, Link, Text, Image } from "@chakra-ui/react";
import { FC } from "react";
import { DELETE_CHAT_MESSAGE_TEXT } from "src/const";
import { isURL } from "src/util/getString";
import { transformChatTime } from "src/util/getTime";
import { Chat, SendUserRoleType } from "src/interface/Chat";
import { BiSolidUserCircle } from "react-icons/bi";
import { ChatText } from "./ChatText";

export const MyChatMessage: FC<{
  chat: Chat;
  beforeChat: Chat | undefined;
  icon: string | undefined | null;
}> = ({ chat, beforeChat, icon }) => {
  const isSameUser = (beforeChat: Chat | undefined, chat: Chat) => {
    if (beforeChat?.chatUserId && chat.chatUserId) {
      return beforeChat.chatUserId === chat.chatUserId;
    }
    if (beforeChat?.chatUserId && !chat.chatUserId) {
      return false;
    }
    if (!beforeChat?.chatUserId && chat.chatUserId) {
      return false;
    }
    if (beforeChat?.intercomAccount && chat.intercomAccount) {
      return beforeChat?.intercomAccount.id === chat.intercomAccount.id;
    }
    return false;
  };
  return (
    <Flex
      alignItems="flex-end"
      display="flex"
      width="100%"
      justifyContent="flex-end"
      position="relative"
      p={!isSameUser(beforeChat, chat) ? "16px 0px 0px 0px" : "0px"}
    >
      <HStack
        alignItems="flex-start"
        maxWidth="calc(100% - 44px)"
        justifyContent="flex-end"
        mt="0px"
        p="0 0 8px"
        position="relative"
      >
        <Box maxWidth="100%">
          {!isSameUser(beforeChat, chat) && (
            <Flex pb="8px">
              <Box m="1px 8px 0px 0px">
                {chat.userType === "user" && (
                  <Text fontSize="12px" fontWeight={300}>
                    {transformChatTime(chat.timestamp)}
                  </Text>
                )}
              </Box>
              <Text
                textAlign="left"
                fontSize="14px"
                fontWeight={600}
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {chat?.intercomAccount?.name}
              </Text>
            </Flex>
          )}
          <Box height="100%">
            {chat.text === DELETE_CHAT_MESSAGE_TEXT ? (
              <Box
                p="8px 10px 0px 10px"
                borderRadius="8px 0px 8px 8px"
                textAlign="left"
                maxWidth="420px"
              >
                <Text fontSize="13px" color="#1A202C" wordBreak="break-all">
                  {DELETE_CHAT_MESSAGE_TEXT}
                </Text>
              </Box>
            ) : (
              <Box
                background={
                  chat.userType === "user"
                    ? "rgba(233, 249, 239, 1)"
                    : "rgba(0,0,0, 0.3)"
                }
                p="8px 10px 8px 10px"
                borderRadius="8px 0px 8px 8px"
                textAlign="left"
                maxWidth="420px"
              >
                {isURL(chat.text) ? (
                  <Link href={chat.text} isExternal>
                    <Text
                      fontSize="13px"
                      color="blue.500"
                      wordBreak="break-all"
                    >
                      {chat.text}
                    </Text>
                  </Link>
                ) : (
                  <ChatText text={chat.text} />
                )}
              </Box>
            )}
          </Box>
        </Box>
        {!isSameUser(beforeChat, chat) ? (
          <Box w="40px" minW="40px" h="65px" mr="8px" pt="0px">
            <Box w="40px" minW="40px" h="40px">
              {icon ? (
                <Image w="40px" h="40px" borderRadius="80%" src={icon ?? ""} />
              ) : (
                <Box
                  boxSize="24px"
                  mr="1"
                  p="0px 0px"
                  w="40px"
                  h="40px"
                  borderRadius="full"
                  textAlign="center"
                >
                  <BiSolidUserCircle
                    color="rgba(34, 197, 94, 1)"
                    fontSize="48px"
                  />
                </Box>
              )}
            </Box>
            <Box mt="8px">
              <Text
                m="0px 0px 0px 4px"
                textAlign="left"
                fontSize="12px"
                fontWeight={600}
                color={
                  chat?.userRole === SendUserRoleType.RemoteUser
                    ? "rgba(0, 160, 233, 1)"
                    : "rgba(93, 195, 104, 1)"
                }
              >
                {chat?.userRole === SendUserRoleType.RemoteUser
                  ? "遠隔"
                  : "店舗"}
              </Text>
            </Box>
          </Box>
        ) : (
          <Box w="40px" minW="40px" h="30px" mr="8px" />
        )}
      </HStack>
    </Flex>
  );
};
