import { useEffect } from "react";
import {
  useMeQuery,
  Role,
  useGetCustomVariablesForVoytQuery,
  GetCustomVariablesForVoytQuery,
} from "src/gql/generated/graphql";
import { useToast } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { meAtom } from "src/pages/Main/atoms/me";
import { useQueryString } from "src/hooks/useQueryString";
import { PATH } from "src/const";
import { useFeatureToggle } from "src/hooks/useFeatureToggle";
import { Feature } from "src/gql/generated/graphql";
import { VARIABLE } from "src/const";
import { isBlank } from "src/util/textUtil";
import { meVoytAtom } from "src/pages/Main/atoms/me";
import { useNavigate } from "react-router-dom";

/** ユーザ情報的に使用できるかを確認する*/
export const useAuth = () => {
  const [{ data: myData }] = useMeQuery();
  const [me, setMe] = useAtom(meAtom);
  const shopId = useQueryString("shop_id");
  const { isEnable } = useFeatureToggle();
  const viewShopChat = isEnable(Feature.ShopChat);
  const [meVoyt, setMeVoyt] = useAtom(meVoytAtom);
  const toast = useToast();
  const navigate = useNavigate();

  const [{ data: customVariableData }] = useGetCustomVariablesForVoytQuery({
    variables: {
      variables: [VARIABLE.VOYT_USER_ID, VARIABLE.VOYT_PASSWORD],
      owner: me?.id ?? "",
    },
    pause: isBlank(me?.id),
  });

  useEffect(() => {
    // tokenが誤っていて失敗時のエラー
    if (myData === null) {
      toast({
        title: "tokenが誤っています。ログイン画面に遷移します。",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        navigate(PATH.LOGIN);
      }, 3000);
    }
    setMe(myData?.me);
  }, [myData, setMe]);

  useEffect(() => {
    // ユーザが所属しているルームに存在していないPCをshop_idとして指定した場合ログイン画面に戻す
    if (!viewShopChat) return;
    if (!shopId) return;
    if (!me?.roomGroup?.rooms) return;
    if (me?.role !== Role.Supporter) return;
    let matchedShop = undefined;
    me?.roomGroup?.rooms.forEach((room) => {
      const tmpMatchedShop = room.pcs?.find((pc) => pc.datastoreId === shopId);
      // matchedShopgあったらループを抜ける
      if (tmpMatchedShop) {
        matchedShop = tmpMatchedShop;
        return;
      }
    });
    if (!matchedShop) {
      toast({
        title:
          "店舗IDがユーザに紐づいているルームグループに紐づいていません。ログイン画面に遷移します。",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        navigate(PATH.SHOP_LOGIN);
      }, 3000);
    }
  }, [me, viewShopChat, shopId, toast]);

  useEffect(() => {
    // 店舗チャットがONなのかも確認する
    if (me?.role === Role.Supporter) {
      if (!viewShopChat) {
        alert(
          "指定のアカウントでは権限がありません。ログイン画面に遷移します。"
        );
        navigate(PATH.SHOP_LOGIN);
        return;
      } else if (!shopId) {
        toast({
          title:
            "サポーターの場合は店舗IDが必要です。ログイン画面に遷移します。",
          status: "error",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          navigate(PATH.SHOP_LOGIN);
        }, 3000);
        return;
      }
    }
  }, [shopId, me, viewShopChat, toast]);

  /** 変数名別のビットレート値 */
  const getVariableValue = (
    variable: GetCustomVariablesForVoytQuery | undefined
  ) => {
    const result = new Map<string, string>();
    variable?.customVariables.nodes.reduce((acc, node) => {
      return acc.set(node.variable, node.value);
    }, result);
    return result;
  };

  useEffect(() => {
    // カスタム変数が存在するかを確認する
    if (!me) return;
    if (!customVariableData) return;
    const voytSignInInfoByVariable = getVariableValue(customVariableData);
    if (voytSignInInfoByVariable.size === 0) return;
    if (
      voytSignInInfoByVariable.has(VARIABLE.VOYT_USER_ID) &&
      voytSignInInfoByVariable.has(VARIABLE.VOYT_PASSWORD)
    ) {
      setMeVoyt({
        userId:
          voytSignInInfoByVariable.get(VARIABLE.VOYT_USER_ID)?.toString() ?? "",
        password:
          voytSignInInfoByVariable.get(VARIABLE.VOYT_PASSWORD)?.toString() ??
          "",
      });
      console.log("voytのユーザIDとパスワードがセットされました");
    } else {
      navigate(PATH.LOGIN);
      return;
    }
  }, [me, customVariableData]);
};
