import { Text, TextProps } from "@chakra-ui/react";
import { memo } from "react";

type ChatTextProps = TextProps & {
  text: string;
};
export const ChatText = memo(({ text, ...props }: ChatTextProps) => {
  return (
    <Text
      fontSize="13px"
      wordBreak="break-all"
      whiteSpace="pre-wrap"
      {...props}
    >
      {text}
    </Text>
  );
});
