import { IoSettings } from "react-icons/io5";
import { FC, useCallback } from "react";
import {
  IconButton,
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  useDisclosure,
  Text,
  Button,
  useToast,
} from "@chakra-ui/react";
import { UserStatusType } from "src/interface/Chat";
import { useAtomValue } from "jotai";
import { meAtom, meInfoAtom } from "src/pages/Main/atoms/me";
import { databaseAtom } from "src/pages/Main/atoms/firebase";
import { updateUser } from "src/util/realtimedb/updateUserInfo";
import { Cookies } from "react-cookie";
import { cookieKeyToken, cookieKeyVoytToken } from "src/util/token";
import { isDevEnv } from "src/util/env";

export const ChatSettingModal: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const me = useAtomValue(meAtom);
  const meInfo = useAtomValue(meInfoAtom);
  const database = useAtomValue(databaseAtom);

  const updateStatus = useCallback(
    (status: UserStatusType) => {
      if (!database || !me || !meInfo) return;
      updateUser(
        database,
        me,
        status,
        meInfo.noticeMute ?? true,
        meInfo.isToastOn ?? true
      );
    },
    [database, me, meInfo]
  );

  const sevaFailedToken = () => {
    const cookie = new Cookies();
    cookie.set(cookieKeyToken, "failedToken", {
      maxAge: 10 * 24 * 60 * 60,
      path: "/",
      domain: isDevEnv() ? "localhost" : ".rura.tokyo",
    });
    toast({
      title: "誤ったtokenを保存しました",
      status: "info",
      position: "top-right",
      duration: 3000,
      isClosable: true,
    });
  };

  const sevaFailedVoytToken = () => {
    const cookie = new Cookies();
    cookie.set(cookieKeyVoytToken, "failedToken", {
      maxAge: 10 * 24 * 60 * 60,
      path: "/",
      domain: isDevEnv() ? "localhost" : ".rura.tokyo",
    });
    toast({
      title: "誤ったvoyt_tokenを保存しました",
      status: "info",
      position: "top-right",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader
            fontFamily="BlinkMacSystemFont"
            pl="0"
            ml="33px"
            fontSize="16px"
            fontWeight="400"
          >
            インカムの設定
          </ModalHeader>
          <ModalBody
            ml="33px"
            mr="27px"
            p="0"
            borderTop="solid 1px rgba(0, 0, 0, 0.25)"
            maxHeight="300px"
            overflowY="scroll"
            className="hide-scrollbar"
            sx={{ WebkitScrollbar: { display: "none" } }}
          >
            <Text mt="10px">ユーザ名：{me?.name}</Text>
            <Box mt="8px">
              <Button onClick={sevaFailedToken}>誤ったtokenを保存する</Button>
              <Button onClick={sevaFailedVoytToken} mt="8px">
                誤ったvoyt用tokenを保存する
              </Button>
            </Box>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <IconButton
        icon={<IoSettings />}
        aria-label="チャットの設定"
        borderRadius="100px"
        cursor="pointer"
        background="rgba(128,128,128,0.3)"
        _hover={{
          background: "rgba(128,128,128,0.1)",
        }}
        _active={{
          background: "rgba(128,128,128,0.1)",
        }}
        onClick={() => {
          onOpen();
        }}
      />
    </Box>
  );
};
